$colorLogo: #ffffff;
$colorTitle: #ffffff;
$colorDescription: #cacaca;
$colorMain: #fe2c55;
$colorBG: #111111;

%title {
    width: 100%;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    color: $colorTitle;
    margin-bottom: 20px;
}

%medium-title {
    width: 100%;
    font-weight: 700;
    font-size: 42px;
    line-height: 64px;
    color: $colorTitle;
    margin-bottom: 20px;
}

@media screen and (max-width: 1149px) {
    %title {
        text-align: center;
    }

    %medium-title {
        text-align: center;
    }
}

@media screen and (max-width: 540px) {
    %title {
        font-size: 34px;
        line-height: 42px;
        margin-bottom: 16px;
    }

    %medium-title {
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 16px;
    }
}

%link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: $colorLogo;
}

.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.main,
.containerSlider {
    position: relative;
}

.root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.container {
    padding: 8px var(--pd-body-left);
}

footer {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    &__copyright {
        text-align: center;
        opacity: 0.7;
        font-size: 0.9em;

        span {
            white-space: nowrap;
        }
    }
    &__contact_information {
        text-align: center;
        margin-bottom: 8px;
        font-size: 1em;
        opacity: 1;
    }
}

// Header

.header {
    display: flex;
    align-items: center;
    gap: 20px;
    width: min-content;
    margin-bottom: 80px;

    .logo__img {
        width: 32px;
        height: 32px;

        & img {
            width: 100%;
            height: 100%;
        }
    }

    p {
        font-size: 24px;
        line-height: 29px;
        font-weight: 600;
        color: $colorLogo;
    }
}

@media screen and (max-width: 1149px) {
    .header {
        margin: 0 auto 40px;
    }
}

@media screen and (max-width: 540px) {
    .header {
        margin-bottom: 26px;
    }
}

// Main
.wrapper {
    display: flex;
    align-items: center;
    gap: 125px;
}

.screenshots_wrapper {
    display: flex;
    flex-direction: row;
}

.screenshot_img {
    height: 40em;
    border-radius: 12px;
    padding: 4px;
}

.verify_wrapper {
    @extend .wrapper;
}

.about__activity {
    flex: 1 0 auto;
    max-width: 410px;
    display: flex;
    flex-direction: column;
    z-index: 10;

    &_title {
        @extend %title;
    }

    &_text {
        max-width: 300px;
        margin-bottom: 28px;
    }
}

.verify__activity {
    flex: 1 0 auto;
    max-width: 410px;
    display: flex;
    flex-direction: column;
    z-index: 10;

    &_title {
        @extend %medium-title;
    }

    &_text {
        max-width: 300px;
        margin-bottom: 28px;
    }
}
.verify__error {
    &-text {
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 1149px) {
    .wrapper {
        flex-direction: column;
        justify-content: center;
        gap: 32px;
    }

    .about__activity {
        max-width: 494px;
        justify-content: center;

        &_text {
            align-self: center;
            text-align: center;
        }
    }

    .verify__activity {
        max-width: 494px;
        justify-content: center;

        &_text {
            align-self: center;
            text-align: center;
        }
    }
}

@media screen and (max-width: 540px) {
    .wrapper {
        gap: 20px;
    }

    .about__activity {
        max-width: 345px;

        &_text {
            margin-bottom: 24px;
            text-align: center;
        }
    }

    .verify__activity {
        max-width: 345px;

        &_text {
            argin-bottom: 24px;
            text-align: center;
        }
    }

    .screenshots_wrapper {
        flex-direction: column;
    }
}

.field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 6px;
    padding: 12px;
    transition: all 0.3s ease-in-out 0s;
    background: rgba($colorMain, 0.1);
    color: white;
}

.fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    &_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 100%;
        border-radius: 6px;
        padding: 12px;
        transition: all 0.3s ease-in-out 0s;
        background: rgba($colorMain, 0.1);
        border: 1px solid rgba($colorMain, 0.2);
        border-color: transparent;
    }
}

.buttons__links {
    display: flex;
    align-items: center;

    gap: 12px;

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 100%;
        max-width: 154px;
        border-radius: 6px;
        padding: 12px;
        transition: all 0.3s ease-in-out 0s;
        background: rgba($colorMain, 0.1);
        border: 1px solid rgba($colorMain, 0.2);
        border-color: transparent;

        p {
            @extend %link;

            // flex: 1;
        }
        &::before {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
        }

        &_googlePlay {
            &::before {
                background: url(../assets/images/GooglePlay.svg) no-repeat
                    center;
            }
        }
        &_home {
            &::before {
                display: none;
            }
        }
        &_appStore {
            &::before {
                background: url(../assets/images/Apple.svg) no-repeat center;
            }
        }
    }
}

@media screen and (max-width: 1149px) {
    .buttons__links {
        justify-content: center;
    }
}
@media screen and (max-width: 540px) {
    .buttons__links {
        gap: 4px;
        gap: 10px;

        .btn {
            justify-content: center;
        }
    }
}

@media screen and (max-width: 374px) {
    .buttons__links {
        flex-direction: column;
        gap: 8px;

        .btn {
            max-width: 100%;
            padding: 12px 8px;
            p {
                width: auto;
            }
        }
    }
}

@media (any-hover: hover) {
    .btn {
        &_googlePlay,
        &_appStore {
            &:hover {
                border: 1px solid rgba($colorMain, 0.7);
            }
        }
    }
}
